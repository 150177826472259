import { Component, OnInit } from '@angular/core';
import { RegistrationService } from "../../services/registration.service";
import { RegistrationForm } from '../../models/registrationForm';
import { Router } from '@angular/router';

import { User, IUser } from '../../models/user';

import {AppSettings} from "../../app.settings";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.scss']
})
export class RegistrationSuccessComponent implements OnInit {

  claims: String;
  currentUser: User;

  pcClaimsUrl = AppSettings._pcRegistrationClaims;

  pcSubmitUrl = AppSettings._pcPassthrough;

  eServicesSumbitUrl = AppSettings._eServicesPassthrough;

  accountAdmin: string;

  useAgreementAgree = false;

  constructor(private router: Router) { 
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.claims = sessionStorage.getItem("pcClaimsSubmissionOptIn");

    this.accountAdmin = localStorage.getItem("eservicesAdminStatus");
  }

  ngOnInit() {
    if(this.accountAdmin == "false"){
      document.getElementById('subAdminRegisterSucessMessage').style.display = 'inherit';
    }

  }

  processRegistration(){
    this.clearStorage();
    this.router.navigateByUrl('/login');
  }

  clearStorage(): void {  
    localStorage.clear();
    sessionStorage.clear();
  }

}
