import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { RegistrationComponent } from "./registration/registration.component";
import { RegistrationInfoComponent } from "./registration/registration-info/registration-info.component";
import { RegistrationReviewComponent } from "./registration/registration-review/registration-review.component";
import { RegistrationSuccessComponent } from "./registration/registration-success/registration-success.component";
import { UseAgreementComponent } from "./use-agreement/use-agreement.component";
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'registration', component: RegistrationComponent,
    children: [
      {
        path: ''
        , redirectTo: 'info', pathMatch: 'full'
      },
      {
        path: 'info',
        component: RegistrationInfoComponent
      },
      {
        path: 'review',
        component: RegistrationReviewComponent
      },
      {
        path: 'success',
        component: RegistrationSuccessComponent
      },
    ]
  },
  { path: 'use-agreement', component: UseAgreementComponent },
  { path: 'forgot-username', component: ForgotUsernameComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
