import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationForm } from '../models/registrationForm';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  pageTitle = "Registration";
  router:Router;
  step = 1;

  form: RegistrationForm;

  constructor(router: Router) { 
    localStorage.removeItem("registrationInfo");
    this.router=router;
  }

  ngOnInit() {
    this.form=new RegistrationForm();
  }
  
  onActivate(event) {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView({ behavior: "smooth"});
      top = null;
    }
  }
}
