import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";

@Component({
  providers: [AuthenticationService],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: Object;

  public message: String;

  username;
  password;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (localStorage.getItem('message') == 'Password Change Successful') {
      document.getElementById('pwdResetSucessMessage').style.display = 'inherit';
      localStorage.removeItem('message');
    }
    else if (localStorage.getItem('message') != null) {
      this.message = localStorage.getItem('message');
      localStorage.removeItem('message');
    }
  }

  validateLogin() {
    if (this.username != null && this.password != null) {
      this.loginForm = {
        userName: this.username,
        password: this.password
      };
      this.authenticationService.authenticate(this.loginForm).subscribe(
        data => {
          let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
          //console.log(currentUser);
          if (currentUser != null && currentUser.firstName != null) {
            this.router.navigateByUrl('/use-agreement');
          } else {
            let errorMsg = "Username and password combination not recognized";
            
            this.message = errorMsg;
          }
        }, err => {
          /*this.isLoading = false;*/
          console.error('Error', err);
          //this.router.navigateByUrl(`/logout`);
        }
      );
    } else {
      alert("Please enter a username and password");
    }
  }
}
