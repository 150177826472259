import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { checkAndUpdateBinding } from '@angular/core/src/view/util';
import { ResetPasswordForm } from '../models/resetPasswordForm';
import { ModalService } from '../services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [AuthenticationService, ModalService],
})

export class ResetPasswordComponent implements OnInit {
  form: ResetPasswordForm;
  formValidity = true;
  formSubmitted = false;

  constructor(public authenticationService: AuthenticationService, private router: Router, private modalService: ModalService) {

  }

  ngOnInit() {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser != null) {
      this.form = { userName: currentUser.username, oldPassword: '', password: '', passwordConfirm: '', source: currentUser.source, mhsProviderId: currentUser.provNo };
    } else {
      this.form = { userName: '', oldPassword: '', password: '', passwordConfirm: '', source: '', mhsProviderId: '' };
    }
  }

  errorTitle = '';
  errorMessage = '';

  processForm() {
    const form = this.form;
    this.formSubmitted = true;
    if (form.password &&
      form.password === form.passwordConfirm &&
      form.password.match('^(?=.*[A-Z])') &&
      form.password.match('^(?=.*[a-z])') &&
      form.password.match('^(?=.*[0-9])') &&
      form.password.match('^(?=.*[$!%*?&\#^()~\'+\-,;:+\\\[\\\]{}_`<>|\\\\])') &&
      form.password.length >= 8 &&
      form.password.length <= 20) {
      this.formValidity = true;
      this.authenticationService.changePassword(this.form).subscribe(data => {
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        //console.log(currentUser);
        if (currentUser != null && currentUser.username != null) {
          localStorage.setItem('message', 'Password Change Successful');
          this.router.navigateByUrl('/login');
        } else {
          console.log("else");
          let errorMsg = "Username and password combination not recognized";

          document.getElementById('resetPwdForm').style.display = 'none';
          document.getElementById('submitbutton').style.display = 'none';
          document.getElementById('fail-title').style.display = 'inherit';
          this.errorTitle = "Password Reset Failed"
          this.errorMessage = "Please call customer service at 1-888-247-9311"

          if (data.message != null)
            errorMsg = data.message;
          //this.message = errorMsg;
        }

      }, err => {
        console.error('Error', err);
        //this.router.navigateByUrl(`/logout`);
      });
    }
    else {
      this.formValidity = false;
    }
  }

}
