export class RegistrationForm {
    constructor(){
        this.password="";
    }

    firstName: string;
    lastName: string;
    contactName: string;
    npiNumber: string;
    taxID: string;
    groupName: string;
    email: string;
    emailConfirm: string;
    secondEmail: string;
    phone: string;
    ext: string;
    fax: string;
    username: string;
    password: string;
    passwordConfirm: string;
    securityQuestion: string;
    answer: string;
    answerConfirm: string;
    
    //PC sepcific
    mhsProviderId: string;
    mhsLibrary:string;
    pcClaimsSubmissionOptIn: boolean;
    horizon: boolean;
    pcMessageCenterOptIn: boolean;

    //ES specific
    esProviderId: string;

}
