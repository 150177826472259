import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-use-agreement-text',
  templateUrl: './use-agreement-text.component.html',
  styleUrls: ['./use-agreement-text.component.scss']
})
export class UseAgreementTextComponent implements OnInit {
  
  constructor() { 

    
  }

  ngOnInit() {
  }

}
