import { Component, OnInit } from '@angular/core';
import { $ } from 'protractor';
import { RegistrationForm } from '../../models/registrationForm';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';

@Component({
  selector: 'app-registration-info',
  templateUrl: './registration-info.component.html',
  styleUrls: ['./registration-info.component.scss']
})
export class RegistrationInfoComponent implements OnInit {
  form: RegistrationForm;
  constructor(private router: Router) {
    //this.form = JSON.parse(localStorage.getItem("registrationInfo"));
    if (this.form == null) {
      this.form = new RegistrationForm();
    }
  }

  ngOnInit() {
  }

  formValidity = true;
  formSubmitted = false;
  telephone = "";
  faxNumber = "";

  processForm() {
    this.formSubmitted = true;
    this.form.username.trim();
    if (this.validateForm()) {
      localStorage.setItem("registrationInfo", JSON.stringify(this.form));
      this.router.navigateByUrl('/registration/review');
    } else {
      return this.formValidity = false;
    }
  }

  numberFormat(event, type) {
    let number = "";
    if (event.key == 'Backspace') {
      number = event.target.value.split('').slice(0, -1).join('');
    } else {
      let numbers = event.target.value.split("").filter(n => n.match('^[0-9]*$'));
      switch (numbers.length) {
        case 0:
          number = "";
          break;
        case 1:
        case 2:
          number = "(" + numbers.join("");
          break;
        case 3:
          number = "(" + numbers.join("") + ") ";
          break;
        case 4:
        case 5:
        case 6:
          number = "(" + numbers.splice(0, 3).join("") + ") " + numbers.join("");
          break;
        case 7:
        case 8:
        case 9:
        default:
          number = "(" + numbers.slice(0, 3).join("") + ") " + numbers.slice(3, 6).join("") + "-" + numbers.slice(6, 10).join("");
          break;
      }
    }

    if (type === "phone") {
      this.telephone = number;
    }
    else if (type === "fax") {
      this.faxNumber = number;
    }
  }

  validateForm() {
    this.form.phone = this.telephone;
    this.form.fax = this.faxNumber;
    const form = this.form;

    if ((form.firstName &&
      form.lastName &&
      form.npiNumber &&
      form.npiNumber.match('^[0-9]*$') &&
      form.npiNumber.length === 10 &&
      form.taxID &&
      form.taxID.match('^[0-9]*$') &&
      form.taxID.length === 9 &&
      form.email &&
      form.email === form.emailConfirm &&
      form.email.match('.+\@.+\..+') &&
      form.phone &&
      form.phone.length === 10 &&
      form.username &&
      form.password &&
      form.password === form.passwordConfirm &&
      form.password.match('^(?=.*[A-Z])') &&
      form.password.match('^(?=.*[a-z])') &&
      form.password.match('^(?=.*[0-9])') &&
      form.password.match('^(?=.*[$!%*?&\#^()~\'+\-,;:+\\\[\\\]{}_`<>|\\\\])') &&
      form.password.length >= 8 &&
      form.password.length <= 20 &&
      form.securityQuestion &&
      form.answer &&
      form.answer === form.answerConfirm
    ) &&
      (form.secondEmail ? form.secondEmail.match('.+\@.+\..+') : true) &&
      (form.ext ? (form.ext.match('^[0-9]*$') && form.ext.length <= 8) : true)  &&
      (form.fax ? form.fax.length === 10 : true)
    ) {
      return true;
    }
    else {
      return false;
    }
  }
}