import { Injectable } from '@angular/core';
import { Http, Request, RequestMethod, RequestOptions, BaseRequestOptions, Response, Headers, HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from "../app.settings";

@Injectable({
    providedIn: 'root'
})


export class forgotPasswordService {

    constructor(private httpClient: HttpClient, private http: Http) { }

    checkUsernameService(userNameForm: any) {
        let url: string = AppSettings._forgotPasswordUrl;
        var headers = new HttpHeaders({
            'Authorization': 'ddfb30c60f9d70700a89a5c78587dac4',
            'Content-Type': 'application/json'
        });
        return this.httpClient.post<any>(url, userNameForm, { headers: headers })
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(err => of([]))
            )
    }

    submitEServicesCode(codeForm: any) {
        let url: string = AppSettings._forgotPasswordUrl + "/submitEServicesCode";
        var headers = new HttpHeaders({
            'Authorization': 'ddfb30c60f9d70700a89a5c78587dac4',
            'Content-Type': 'application/json'
        });
        return this.httpClient.post<any>(url, codeForm, { headers: headers })
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(err => of([]))
            )
    }

    SubmitEServicesAnswer(answerForm: any) {
        let url: string = AppSettings._forgotPasswordUrl + "/submitEServicesAnswer";
        var headers = new HttpHeaders({
            'Authorization': 'ddfb30c60f9d70700a89a5c78587dac4',
            'Content-Type': 'application/json'
        });
        return this.httpClient.post<any>(url, answerForm, { headers: headers })
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(err => of([]))
            )
    }

}