export class AppSettings {
    private static _baseSppURL =

        'https://providerportal.beaconhealthoptions.com/SingleProviderPortal/rest/';

        // 'http://localhost:9082/SingleProviderPortal/rest/'

    //'https://RES-W10L-JMCCAL.beaconhealthoptions.com:9445/SingleProviderPortal/rest/';

    //'https://res-w10d-kpadma.beaconhealthoptions.com:9444/SingleProviderPortal/rest/';

    public static _authenticateUrl = AppSettings._baseSppURL + 'login/authenticate';

    public static _changePasswordUrl = AppSettings._baseSppURL + 'login/changepwd';

    public static _registrationUrl = AppSettings._baseSppURL + 'user/registration';

    public static _sourceVerifyUrl = AppSettings._baseSppURL + 'user/verify/source';

    public static _forgotUsernameUrl = AppSettings._baseSppURL + 'user/forgotusername';

    public static _forgotPasswordUrl = AppSettings._baseSppURL + 'user/forgotpassword';

    private static _basePcURL = 'https://providerconnect.beaconhealthoptions.com/pc/eProvider/ProviderServicesRedirect.redirectToPC';

    //'http://res-w10d-kpadma.beaconhealthoptions.com:9086/pc/eProvider/ProviderServicesRedirect.redirectToPC';

    public static _pcPassthrough = AppSettings._basePcURL;

    public static _pcRegistrationClaims = AppSettings._basePcURL + '?action=claimsRegistration';

    private static _baseEservicesURL = "https://provider.beaconhealthoptions.com/api/";

    public static _eServicesPassthrough = AppSettings._baseEservicesURL + "account/login";
}
