import { Component, OnInit } from '@angular/core';
import { RegistrationComponent } from "../registration.component";
import { RegistrationForm } from '../../models/registrationForm';
import { Router } from '@angular/router';

import { RegistrationService } from "../../services/registration.service";
import { ModalService } from '../../services/modal.service';

@Component({
  providers: [RegistrationService, ModalService],
  selector: 'app-registration-review',
  templateUrl: './registration-review.component.html',
  styleUrls: ['./registration-review.component.scss']
})
export class RegistrationReviewComponent implements OnInit {

  pageTitle = "Registration";

  additionalInfoDiv = false;
 
  form: RegistrationForm;

  public message: String;

  constructor(private router: Router, private registrationService: RegistrationService, private modalService: ModalService) { 

    this.form = JSON.parse(localStorage.getItem("registrationInfo"));
    if(this.form==null){
      this.router.navigateByUrl('/registration');
    }
  }

  ngOnInit() {
    this.additionalInfoDiv = false;
    localStorage.removeItem("eservicesAdminStatus");
  }

  toggleAdditionalInfo(){
    this.additionalInfoDiv = !this.additionalInfoDiv;
  }

  verifySource(){
    let errorMsg = "This provider could not be registered at this time. Please try again";
    this.registrationService.verifySource(this.form).subscribe(
      data => {
        //console.log(data.user);
        if(data.user!=null){
          if(data.user.source=="PC"){
            this.form.mhsProviderId = data.user.provNo;
            this.toggleAdditionalInfo();
          }else if(data.user.source=="ES"){
            this.form.esProviderId = data.user.provNo;
            //if(data.message == "Success - Not Admin"){
              localStorage.setItem("eservicesAdminStatus","false");
            //}
            this.registerUser();
          }
        }else{
         if(data.message!=null)
            errorMsg = data.message;
         this.message = errorMsg;
         this.moveToTop();
        }
        
      }, err => {
        this.message = errorMsg;
        this.moveToTop();
    });
  }

  registerUser(){

    let errorMsg = "This provider could not be registered at this time. Please try again";
    if(this.form.horizon){
      this.form.mhsLibrary = "H";
    }
    this.registrationService.register(this.form).subscribe(
      data => {
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        //console.log(currentUser);
        if(currentUser!=null && currentUser.firstName !=null){
          currentUser.password = this.form.password;
          sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
          if(this.form.pcClaimsSubmissionOptIn){
            sessionStorage.setItem("pcClaimsSubmissionOptIn", 'true');
          }
          this.router.navigateByUrl('/registration/success');
        }else{
           if(data.message!=null)
              errorMsg = data.message;
            
            this.message = errorMsg;

            //Move to Top
            this.moveToTop();
        }
        
      }, err => {
        this.message = errorMsg;
        this.moveToTop();
        
    });

  }

  moveToTop()
  {
    //Move to Top
    let top = document.getElementById('registrationError');
    if(top!==null) {
      top.scrollIntoView({behavior:"smooth"});
      top=null;
    }
  }
}
