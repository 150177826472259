import { Injectable } from '@angular/core';
import { Http, Request, RequestMethod, RequestOptions, BaseRequestOptions, Response, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from "../app.settings";

@Injectable({
    providedIn: 'root'
})

export class ForgotUsernameService {
    constructor(private httpClient: HttpClient, private http: Http) { }

    check(emailForm: any) {
        let url: string = AppSettings._forgotUsernameUrl;
        var headers = new HttpHeaders({
            'Authorization': 'ddfb30c60f9d70700a89a5c78587dac4',
            'Content-Type': 'application/json'
        });
        return this.httpClient.post<any>(url, emailForm, { headers: headers })
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(err => of([]))
            )
    }
}