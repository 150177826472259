import { Component } from '@angular/core';
import { Router, Routes, NavigationEnd, ActivatedRoute, UrlSegment } from '@angular/router';

//declare gtag function
declare var gtag:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(
    private router: Router, 
    private route: ActivatedRoute
  ){ 

    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        gtag('config', 'UA-129259701-1', {
          'page_path': router.routerState.snapshot.url
        });
      }
    });

  }
  
}
