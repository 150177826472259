import { Injectable } from '@angular/core';
import { Http, Request, RequestMethod, RequestOptions, BaseRequestOptions, Response, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, IUser } from '../models/user';

import { AppSettings } from "../app.settings";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private httpClient: HttpClient, private http: Http) { }

  verifySource(registrationForm: any) {
    let url: string = AppSettings._sourceVerifyUrl;

    console.log(registrationForm);

    var headers = new HttpHeaders({
      'Authorization': 'ddfb30c60f9d70700a89a5c78587dac4',
      'Content-Type': 'application/json'
    });



    return this.httpClient.post<any>(url, registrationForm, { headers: headers })

      .pipe(map(result => {

        return result;

      }));

  }

  register(registrationForm: any) {
    let url: string = AppSettings._registrationUrl;
    sessionStorage.removeItem('currentUser');

    console.log(registrationForm);

    var headers = new HttpHeaders({
      'Authorization': 'ddfb30c60f9d70700a89a5c78587dac4',
      'Content-Type': 'application/json'
    });



    return this.httpClient.post<any>(url, registrationForm, { headers: headers })

      .pipe(map(result => {

        // login successful if there's a jwt token in the response
        //console.log(result);
        if (result && result.user) {

          sessionStorage.setItem('currentUser', JSON.stringify(result.user));

          //console.log(user);

        }



        return result;

      }));

  }

}
