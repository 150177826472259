import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http'; 

import { FormsModule } from '@angular/forms';

import {HashLocationStrategy, LocationStrategy} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { UseAgreementComponent } from './use-agreement/use-agreement.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { RegistrationReviewComponent } from './registration/registration-review/registration-review.component';
import { RegistrationInfoComponent } from './registration/registration-info/registration-info.component';
import { UseAgreementTextComponent } from './use-agreement/use-agreement-text/use-agreement-text.component';
import { RegistrationSuccessComponent } from './registration/registration-success/registration-success.component';
import { ModalComponent } from './directives/modal.component';
import { ModalService } from './services/modal.service';

import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    LoginComponent,
    UseAgreementComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    ForgotUsernameComponent,
    RegistrationReviewComponent,
    RegistrationInfoComponent,
    UseAgreementTextComponent,
    RegistrationSuccessComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpModule
  ],
  providers: [ModalService,{provide:LocationStrategy, useClass:HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
