import { Component, OnInit } from '@angular/core';

import { User, IUser } from '../models/user';

import { Router } from "@angular/router";

import {AppSettings} from "../app.settings";

import { AuthenticationService } from "../services/authentication.service";
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { isUpperCase } from 'tslint/lib/utils';

@Component({
  providers: [AuthenticationService],
  selector: 'app-use-agreement',
  templateUrl: './use-agreement.component.html',
  styleUrls: ['./use-agreement.component.scss']
})
export class UseAgreementComponent implements OnInit {
  public loginForm: FormGroup;
  currentUser: User;
  firstName: String;
  pageTitle = 'Login';

  expired = false;

  pcSubmitUrl = AppSettings._pcPassthrough;

  eServicesSumbitUrl = AppSettings._eServicesPassthrough;

  constructor(private router: Router,private authenticationService: AuthenticationService) { 

    
    
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if(this.currentUser!=null){
        this.firstName = this.currentUser.firstName;
        if(this.currentUser.passwordExpiryDt!=null){
          this.expired = this.currentUser.passwordExpiryDt <= new Date();
        }
        
    }else{
      this.router.navigateByUrl('/login');
    }
    console.log(this.currentUser);
    this.loginForm = new FormGroup({
      username: new FormControl('', [<any>Validators.required, <any>Validators.minLength(1)]),
      password: new FormControl('', [<any>Validators.required, <any>Validators.minLength(1)])
    });
  }

 directUser(){
    let user:IUser;
    user = {
      "UserID": null,
      "Username": this.currentUser.username,
      "FirstName": null,
      "LastName": null,
      "Name": null,
      "Email": null,
      "City": null,
      "State": null,
      "Password": this.currentUser.password,
      "Plans": null,
      "Message": null
  }
    user.Username = this.currentUser.username;
    user.Password = this.currentUser.password;
    this.authenticationService.legacyPassthroughEservices(user);
  }


}
