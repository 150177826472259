import { Component, OnInit } from '@angular/core';
import { ForgotUsernameService } from '../services/forgot-username.service';
import { checkAndUpdateBinding } from '@angular/core/src/view/util';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss'],
  providers: [ForgotUsernameService]
})

@Injectable()
export class ForgotUsernameComponent implements OnInit {
  form: { email: '' }

  constructor(public forgotUsernameService: ForgotUsernameService, @Inject(DOCUMENT) private document: any) {
    this.form = { email: '' }
  }

  ngOnInit() { }

  formValidity = true;

  removeFormElements() {
    this.document.getElementById('message').style.display = "none";
    this.document.getElementById('emailForm').style.display = "none";
    this.document.getElementById('loginbutton').style.display = "none";
  }

  processForm() {
    if (this.form.email.match(/.+\@.+\..+/)) {
      this.formValidity = true;
      this.forgotUsernameService.check(this.form).subscribe(response => {

        if (response.success) {
          this.document.getElementById('forgotUsernameSuccessMessage').style.display = "inherit";
          this.removeFormElements();
        }
        else if (!response.success) {
          this.document.getElementById('forgotUsernameFailureMessage').style.display = "inherit";
          this.removeFormElements();
        }
      })
    }
    else {
      this.formValidity = false;
    }
  }

}
