import { Injectable } from '@angular/core';
import { Http,  Request, RequestMethod, RequestOptions, BaseRequestOptions, Response, Headers  } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, IUser } from '../models/user';

import {AppSettings} from "../app.settings";
import { ResetPasswordForm } from '../models/resetPasswordForm';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private httpClient: HttpClient, private http: Http) { }

  authenticate(loginForm:any) {
    let url:string = AppSettings._authenticateUrl;
    sessionStorage.removeItem('currentUser');

    console.log(loginForm);

    var headers = new HttpHeaders({
      'Authorization': 'ddfb30c60f9d70700a89a5c78587dac4',
      'Content-Type':'application/json'
    });

      

    return this.httpClient.post<any>(url, loginForm, {headers:headers})

        .pipe(map(result => {

            // login successful if there's a jwt token in the response
            //console.log(result);
            if (result && result.user) {

              sessionStorage.setItem('currentUser', JSON.stringify(result.user));

              //console.log(user);

            }



            return result;

        }));

  }

  changePassword(resetPwForm:ResetPasswordForm) {
    let url:string = AppSettings._changePasswordUrl;
    sessionStorage.removeItem('currentUser');

    console.log(resetPwForm);

    var headers = new HttpHeaders({
      'Authorization': 'ddfb30c60f9d70700a89a5c78587dac4',
      'Content-Type':'application/json'
    });

      

    return this.httpClient.post<any>(url, resetPwForm, {headers:headers})

        .pipe(map(result => {

            // login successful if there's a jwt token in the response
            //console.log(result);
            if (result && result.user) {

              sessionStorage.setItem('currentUser', JSON.stringify(result.user));

              //console.log(user);

            }



            return result;

        }));

  }

  legacyPassthroughEservices(user:IUser){
    let url:string = 
    
    //"https://provider-test.beaconhealthoptions.com/api/account/eservicesdemo01/BeaconDemo";
    
    "https://provider.beaconhealthoptions.com/api/account/login";

    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    


    let headers = new Headers({ 'Content-Type': 'application/json'});
        
       
    let options = new RequestOptions({ headers: headers, withCredentials: true });


    this.http.post(url,user,options).subscribe(res => {
      res.json();
    });
    this.http.post(url,user,options).subscribe(res => {
      res.json();
    });
    //this.http.post(url,submitForm);
     // window.location.href = "https://provider-test.beaconhealthoptions.com/welcome.aspx";
  }



  logout() {

    // remove user from local storage to log user out

    localStorage.removeItem('currentUser');

  }
}
