import { Component, OnInit } from '@angular/core';
import { forgotPasswordService } from '../services/forgot-password.service';
import { AuthenticationService } from '../services/authentication.service';
import { checkAndUpdateBinding } from '@angular/core/src/view/util';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { ResetPasswordForm } from '../models/resetPasswordForm'
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  userNameForm: { userName: '', email: '' }
  answerForm: { secretAnswer: '' }
  codeForm: { code: '', userName: '', email: '' }
  passwordForm: ResetPasswordForm;

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    public forgotPasswordService: forgotPasswordService,
    @Inject(DOCUMENT) private document: any) {

    this.userNameForm = { userName: '', email: '' }
    this.answerForm = { secretAnswer: '' }
    this.codeForm = { code: '', userName: '', email: '' }
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser != null) {
      this.passwordForm = { userName: currentUser.username, oldPassword: '', password: '', passwordConfirm: '', source: currentUser.source, mhsProviderId: currentUser.provNo };
    } else {
      this.passwordForm = { userName: '', oldPassword: '', password: '', passwordConfirm: '', source: '', mhsProviderId: '' };
    }
  }

  ngOnInit() {
  }

  secretQuestion = '';
  secretAnswer = '';
  userName = '';
  source = '';
  formValidity = true;
  formSubmitted = false;

  messageFailure() {
    this.document.getElementsByClassName('resetPasswordFormPartOne')[0].style.display = 'block';
    this.document.getElementsByClassName('resetPasswordFormPartTwo')[0].style.display = 'none';
    this.document.getElementsByClassName('resetPasswordFormPartThree')[0].style.display = 'none';
    this.document.getElementById('userNameForm').style.display = 'none';
    this.document.getElementById('loginbutton').style.display = 'none';
    this.document.getElementById('message').style.display = 'none';
    this.document.getElementById('forgotPasswordFailureMessage').style.display = 'inherit';
  }

  processUsername() {
    if (this.userNameForm.userName != null) {
      this.forgotPasswordService.checkUsernameService(this.userNameForm).subscribe(response => {
        if (response.source == 'PC' && response.message == 'Success') {
          this.source = response.source;
          this.secretQuestion = response.secretQuestion;
          this.secretAnswer = response.secretAnswer;
          this.passwordForm.userName = response.userName;
          this.passwordForm.oldPassword = response.password;
          this.passwordForm.mhsProviderId = response.mhsProviderId;
          this.passwordForm.source = response.source;
          this.document.getElementsByClassName('resetPasswordFormPartOne')[0].style.display = 'none';
          this.document.getElementsByClassName('resetPasswordFormPartThree')[0].style.display = 'block';
        }
        else if (response.message == "eServices Success") {
          this.source = "eServices"
          this.document.getElementsByClassName('resetPasswordFormPartOne')[0].style.display = 'none';
          this.document.getElementsByClassName('resetPasswordFormPartTwo')[0].style.display = 'block';
        }
        else {
          this.messageFailure();
        }
      }, err => {
        console.error('Error ', err);
      })
    }
    else {
      this.formValidity = false;
      this.formSubmitted = true;
    }
  }

  processCode() {
    if (this.codeForm.code != null) {
      this.codeForm.userName = this.userNameForm.userName;
      this.userName = this.codeForm.userName;
      this.codeForm.email = this.userNameForm.email;
      this.forgotPasswordService.submitEServicesCode(this.codeForm).subscribe(response => {
        if (response.securityQuestion == "false") {
          this.messageFailure();
        }
        else {
          this.secretQuestion = response.securityQuestion;
          this.document.getElementsByClassName('resetPasswordFormPartTwo')[0].style.display = 'none';
          this.document.getElementsByClassName('resetPasswordFormPartThree')[0].style.display = 'block';
        }
      }, err => {
        console.error('Error ', err);
        this.messageFailure();
      })
    }
  }

  processForm() {
    const form = this.passwordForm;
    this.formSubmitted = true;
    if (form.password &&
      form.password === form.passwordConfirm &&
      form.password.match('^(?=.*[A-Z])') &&
      form.password.match('^(?=.*[a-z])') &&
      form.password.match('^(?=.*[0-9])') &&
      form.password.match('^(?=.*[$!%*?&\#^()~\'+\-,;:+\\\[\\\]{}_`<>|\\\\])') &&
      form.password.length >= 8 &&
      form.password.length <= 20) {
      this.formValidity = true;

      if (this.source == "PC") {
        if (this.secretAnswer.toLowerCase() == this.answerForm.secretAnswer.toLowerCase()) {
          this.authenticationService.changePassword(this.passwordForm).subscribe(data => {
            if (data.message == 'Success') {
              localStorage.setItem('message', 'Password Change Successful');
              this.router.navigateByUrl('/login');
            }
            else {
              this.messageFailure();
            }
          }, err => {
            console.error('Error ', err);
            this.messageFailure();
          });
        }
        else {
          this.messageFailure();
        }
      }
      else if (this.source == "eServices") {
        let form = {
          username: this.userName,
          passKey: "",
          password: this.passwordForm.password,
          passwordConfirm: this.passwordForm.passwordConfirm,
          securityQuestion: this.secretQuestion,
          answer: this.answerForm.secretAnswer.toLowerCase(),
          answerConfirm: this.answerForm.secretAnswer.toLowerCase(),
          ipAddress: "000.000.000",
          sessionID: this.document.cookie.split(";")[0],
        }

        this.forgotPasswordService.SubmitEServicesAnswer(form).subscribe(response => {
          if (response.message == "true") {
            localStorage.setItem('message', 'Password Change Successful');
            this.router.navigateByUrl('/login');
          }
          else {
            this.messageFailure();
          }
        }, err => {
          console.error('Error ', err);
          this.messageFailure();
        })
      }
    }
    else {
      this.formValidity = false;
    }
  }
}